import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/Layout/Layout';
import CheckedInfoBlock from '../../components/CheckedInfoBlock/CheckedInfoBlock';
import { CONTACTS_LINK } from '../../constans/links';
import styles from './index.module.scss';

const Index = () => (
  <Layout mainClasses="section-padded-wrapper flex-grow-1">
    <div className="info-page-container">
      <div className={styles.textual}>
        <div className={`${styles.title} t-700`}>Rating and review services</div>
        <div className={`${styles.mainSubTitle} body-16-reg`}>
          Koreval currently provides the following services:
        </div>
        <CheckedInfoBlock
          title="Desktop review"
        >
          No site visit takes place and the
          analyst relies on information that is publicly
          available information and any materials
          provided by the Seller.
        </CheckedInfoBlock>
        <CheckedInfoBlock
          title="On-site rating"
        >
          Includes a formal visit of the analyst to the property or activity and a
          meeting with management.
        </CheckedInfoBlock>
        <CheckedInfoBlock
          title="On-site rating with customer experience"
        >
          Similar to On-site rating but also includes the
          activity experience or overnight stay of a
          reviewer without the knowledge of the activity/property staff. The reviews and rating
          comments are published on Koralgo and
          the rating data is used by the PV algorithm that underpins the
          ranking of offers.
        </CheckedInfoBlock>
      </div>
      <div className={styles.textual}>
        <div className={`${styles.title} t-700`}>
          Methodology and process
        </div>
        <div className="body-16-reg">
          The analyst carries out an extensive search through the internet and
          compiles relevant information, including the essence of guest reviews.
          The rating is then submitted to a Review Committee for vetting / amendments
          and publication on the website.
        </div>
      </div>
      <div className={styles.textual}>
        <div className={`${styles.title} t-700`}>
          Koreval contact
        </div>
        <div className="body-16-reg">
          Please use this
          <Link
            to={CONTACTS_LINK}
            className={`${styles.hyperlink} t-500`}
          >
            contact form
          </Link>
          if you are interested in Koreval services
        </div>
      </div>
    </div>
  </Layout>
);

export default Index;
