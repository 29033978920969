import React from 'react';
import CheckIcon from '../../assets/img/icons/ic-square-check.svg';
import styles from './CheckedInfoBlock.module.scss';

const CheckedInfoBlock = ({ title, children }) => (
  <div className={styles.subTextual}>
    <CheckIcon className={styles.icon} />
    <div className={`${styles.subtitle} t-700`}>
      {title}
    </div>
    <div className="body-16-reg">
      {children}
    </div>
  </div>
);

export default CheckedInfoBlock;
